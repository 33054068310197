import React, { useEffect, useState } from 'react';
import {
    Container,
} from '@material-ui/core';
import config from '../../config';

function EditTournament() {
    return (
        <div>hello world, it's tournament time</div>
    );
}

export default EditTournament;