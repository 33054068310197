import React, { useState } from 'react';
import { getEnvironments } from '../../utils';
import logo from '../../assets/mc-logo.png';
import MenuSection from './MenuSection';
import MenuItem from './MenuItem';
import '../../App.css';

// MATERIAL-UI V4
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'

// MATERIAL-UI ICONS V4
import {
  AddBox as AddIcon,
  Apps as HomeIcon,
  Delete as DeleteIcon,
  Description as ArticleIcon,
  Edit as EditIcon,
  EmojiEvents as TournamentIcon,
  InfoOutlined as ActivityIcon,
  List as ListIcon,
  LocalOffer as TagIcon,
  MenuBook as GlossaryIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Publish as PromoteIcon,
  ViewList as TemplateIcon,
} from '@material-ui/icons';


function AppMenu(props) {
  const [expandedSection, setExpandedSection] = useState('');
  const path = window.location.pathname;
  const pathRoot = path.split('/')[1];
  const destinationEnvironment = getEnvironments().destinationEnvironment;
  const formattedDestinationEnv = destinationEnvironment.charAt(0).toUpperCase() + destinationEnvironment.slice(1);

  function expandCollapse(sectionName) {
    if (expandedSection === sectionName) {
      setExpandedSection('');
    } else {
      setExpandedSection(sectionName);
    }
  }

  const menuSectionProps = { pathRoot, expandedSection, expandCollapse }

  return (
    <Drawer
      open={props.drawerOpen}
      variant={props.persistentMenu ? 'persistent' : 'temporary'}
      onClose={() => props.setDrawerOpen(false)}
    >
      <div className='mission-control-logo'><a href='/' style={path === '/' ? { pointerEvents: 'none' } : {}} ><img src={logo} /></a></div>
      <List>
        {/* HOME */}
        <ListItem button disabled={path === '/'} component='a' href='/' >
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary='Home' />
        </ListItem>
        <Divider />

        {/* ARTICLES */}
        <MenuSection label='Articles' root='articles' icon={<ArticleIcon />} {...menuSectionProps} disable={!props.securityGroup?.articles} />
        <Collapse in={expandedSection === 'articles' || pathRoot === 'articles'} >
          <MenuItem nested security={props.securityGroup?.articles} link='/articles/create' icon={<AddIcon />} label='New Article' path={path} />
          <MenuItem nested security={props.securityGroup?.articles} link='/articles/update' icon={<EditIcon />} label='Update Article' path={path} />
          <MenuItem nested security={props.securityGroup?.articles} link='/articles/delete' icon={<DeleteIcon />} label='Delete Article' path={path} />
          <MenuItem nested security={props.securityGroup?.articles} link='/articles/promote' icon={<PromoteIcon />} label={`Promote Article to ${formattedDestinationEnv}`} path={path} />
        </Collapse>

        {/* TEMPLATES */}
        <MenuSection label='Templates' root='templates' icon={<TemplateIcon />} {...menuSectionProps} disable={!props.securityGroup?.templates} />
        <Collapse in={expandedSection === 'templates' || pathRoot === 'templates'} >
          <MenuItem nested security={props.securityGroup?.templates} link='/templates/publish' icon={<AddIcon />} label='Upload Template' path={path} />
          <MenuItem nested security={props.securityGroup?.templates} link='/templates/promote' icon={<PromoteIcon />} label={`Promote to ${formattedDestinationEnv}`} path={path} />
        </Collapse>

        {/* NOTIFICATIONS */}
        <MenuItem security={props.securityGroup?.notifications} link='/notifications/activity' icon={<NotificationsIcon />} label='Notifications' path={path} />
        <Collapse in={expandedSection === 'notifications' || pathRoot === 'notifications'} >
          <MenuItem nested security={props.securityGroup?.notifications} link='/notifications/activity' icon={<ActivityIcon />} label='Notification Activity' path={path} />
          <MenuItem nested security={props.securityGroup?.notifications} link='/notifications/create' icon={<AddIcon />} label='New Notification' path={path} />
        </Collapse>

        {/* GLOSSARY */}
        <MenuItem security={props.securityGroup?.glossary} link='/glossary' icon={<GlossaryIcon />} label='Glossary' path={path} />

        {/* TAGS */}
        <MenuSection label='Tags' root='tags' icon={<TagIcon />} {...menuSectionProps} disable={!props.securityGroup?.tags} />
        <Collapse in={expandedSection === 'tags' || pathRoot === 'tags'} >
          <MenuItem nested security={props.securityGroup?.tags} link='/tags/edit-tags' icon={<EditIcon />} label='Edit Tags' path={path} />
          <MenuItem nested security={props.securityGroup?.tags} link='/tags/edit-tag-groups' icon={<EditIcon />} label='Edit Tag Groups' path={path} />
        </Collapse>

        {/* VEHICLE MENU */}
        <MenuSection label='Vehicle Menu' root='vehicle-menu' icon={<ListIcon />} {...menuSectionProps} disable={!props.securityGroup?.vehicleMenu} />
        <Collapse in={expandedSection === 'vehicle-menu' || pathRoot === 'vehicle-menu'} >
          <MenuItem nested security={props.securityGroup?.vehicleMenu} link='/vehicle-menu/update' icon={<AddIcon />} label='Update Vehicle Image' path={path} />
          <MenuItem nested security={props.securityGroup?.vehicleMenu} link='/vehicle-menu/promote' icon={<PromoteIcon />} label='Promote Vehicle Image' path={path} />
        </Collapse>

        {/* TOURNAMENTS MENU */}
        <MenuItem security={props.securityGroup?.tournaments} label='Tournaments' link='/tournaments/manage-tournaments' icon={<TournamentIcon />} {...menuSectionProps} path={path}/>
        <Collapse in={expandedSection === 'tournaments' || pathRoot === 'tournaments'} >
          <MenuItem nested security={props.securityGroup?.tournaments} link='/tournaments/manage' icon={<ListIcon />} label='Manage Tournaments' path={path} />
          <MenuItem nested link='/tournaments/edit' icon={<EditIcon />} label='Edit Tournament' path={path} />
        </Collapse>

        {/* GUEST USER MENU */}
        <MenuSection label='Guest User Menu' root='guest-user' icon={<PersonIcon />} {...menuSectionProps} disable={!props.securityGroup?.guestUser} />
        <Collapse in={expandedSection === 'guest-user' || pathRoot === 'guest-user'} >
          <MenuItem nested security={props.securityGroup?.guestUser} link='/guest-user/create' icon={<AddIcon />} label='Create Guest User' path={path} />
        </Collapse>
      </List>
    </Drawer>
  );
}

export default AppMenu;
